import { combineReducers } from '@reduxjs/toolkit'
import userSettingsReducer from '../userSettings/redux/userSettingsSlice';
import { DATA_ASSET_TYPE } from '../../businessGlossary/dataAsset/types/dataAssetTypes';
import dataAssetReducer from '../../businessGlossary/dataAsset/redux/dataAssetSlice';
import { DATA_SET_TYPE } from '../../businessGlossary/dataSet/types/dataSetTypes';
import dataSetReducer from '../../businessGlossary/dataSet/redux/dataSetSlice';
import { DATA_STRUCTURE_TYPE } from '../../businessGlossary/dataStructure/types/dataStructureTypes';
import dataStructureReducer from '../../businessGlossary/dataStructure/redux/dataStructureSlice';
import { STATUS_TYPE } from '../../businessGlossary/status/types/statusTypes';
import statusReducer from '../../businessGlossary/status/redux/statusSlice';
import businessTermReducer from '../../businessGlossary/businessTerm/redux/businessTermSlice';
import { BUSINESS_TERM_TYPE } from '../../businessGlossary/businessTerm/types/businessTermTypes';
import dataFieldReducer from '../../businessGlossary/dataField/redux/dataFieldSlice';
import { DATA_FIELD_TYPE } from '../../businessGlossary/dataField/types/dataFieldTypes';
import userMessagesReducer from '../userMessages/redux/userMessagesSlice';
import { USER_TYPE } from '../../businessGlossary/user/types/userTypes';
import userReducer from '../../businessGlossary/user/redux/userSlice';
import notificationCenterReducer from '../notificationCenter/notificationCenterSlice';
import appSettingsReducer from '../appSettings/redux/appSettingsSlice';
import customPropertyGroupReducer from '../customProperties/customPropertyGroup/redux/customPropertyGroupSlice';
import { CUSTOM_PROPERTY_GROUP_TYPE } from '../customProperties/customPropertyGroup/types/customPropertyGroupTypes';
import customPropertyReducer from '../customProperties/customProperty/redux/customPropertySlice';
import { CUSTOM_PROPERTY_TYPE } from '../customProperties/customProperty/types/customPropertyTypes';
import { DATA_SOURCE_TYPE } from '../../businessGlossary/dataSource/types/dataSourceTypes';
import dataSourceReducer from '../../businessGlossary/dataSource/redux/dataSourceSlice';
import { BUSINESS_RULE_TYPE } from '../../businessGlossary/businessRule/types/businessRuleTypes';
import businessRuleReducer from '../../businessGlossary/businessRule/redux/businessRuleSlice';
import { ENTITY_TYPE } from '../../businessGlossary/entity/types/entityTypes';
import entityReducer from '../../businessGlossary/entity/redux/entitySlice';
import { COMPOSITE_TYPE_TYPE } from '../../businessGlossary/compositeType/types/compositeTypeTypes';
import compositeTypeReducer from '../../businessGlossary/compositeType/redux/compositeTypeSlice';
import { ATTRIBUTE_DEFINITION_TYPE } from '../../businessGlossary/attributeDefinition/types/attributeDefinitionTypes';
import attributeDefinitionReducer from '../../businessGlossary/attributeDefinition/redux/attributeDefinitionSlice';
import { ATTRIBUTE_TYPE } from '../../businessGlossary/attribute/types/attributeTypes';
import attributeReducer from '../../businessGlossary/attribute/redux/attributeSlice';
import { VALUE_TYPE_TYPE } from '../../businessGlossary/valueType/types/valueTypeTypes';
import valueTypeReducer from '../../businessGlossary/valueType/redux/valueTypeSlice';
import { REQUIREMENT_TYPE } from '../../businessGlossary/requirement/types/requirementTypes';
import requirementReducer from '../../businessGlossary/requirement/redux/requirementSlice';
import { BUSINESS_MODEL_DIAGRAM_TYPE } from '../../businessGlossary/businessModelDiagram/types/businessModelDiagramTypes';
import businessModelDiagramSlice from '../../businessGlossary/businessModelDiagram/redux/businessModelDiagramSlice';
import { BUSINESS_MODEL_MAPPING_TYPE } from '../../businessGlossary/businessModelMapping/types/businessModelMappingTypes';
import businessModelMappingSlice from '../../businessGlossary/businessModelMapping/redux/businessModelMappingSlice';
import { PROCESS_TYPE } from '../../businessGlossary/process/types/processTypes';
import processSlice from '../../businessGlossary/process/redux/processSlice';
import { PROCESS_STEP_TYPE } from '../../businessGlossary/process/step/types/processStepTypes';
import processStepSlice from '../../businessGlossary/process/step/redux/processStepSlice';
import { PROCESS_MAPPING_TYPE } from '../../businessGlossary/process/mapping/types/processMappingTypes';
import processMappingSlice from '../../businessGlossary/process/mapping/redux/processMappingSlice';
import { TECHNICAL_DATA_MAPPING_TYPE } from '../../businessGlossary/technicalModelMapping/types/technicalDataMappingTypes';
import technicalDataMappingReducer from '../../businessGlossary/technicalModelMapping/redux/technicalDataMappingSlice';
import bulkOperationsSlice from '../bulkOperations/redux/bulkOperationsSlice';
import columnProfilingReducer from '../columnProfiling/redux/columnProfilingSlice';
import { PROCESS_DIAGRAM_TYPE } from '../../businessGlossary/processDiagram/types/processDiagramTypes';
import processDiagramSlice from '../../businessGlossary/processDiagram/redux/processDiagramSlice';
import dataSetScanReducer from '../dataCatalogSync/redux/dataSetScanSlice';

// TODO inject from businessGlossary

const rootReducer = combineReducers({
  [DATA_ASSET_TYPE]: dataAssetReducer,
  [DATA_SET_TYPE]: dataSetReducer,
  [DATA_STRUCTURE_TYPE]: dataStructureReducer,
  [STATUS_TYPE]: statusReducer,
  [BUSINESS_TERM_TYPE]: businessTermReducer,
  [BUSINESS_RULE_TYPE]: businessRuleReducer,
  [ENTITY_TYPE]: entityReducer,
  [COMPOSITE_TYPE_TYPE]: compositeTypeReducer,
  [ATTRIBUTE_DEFINITION_TYPE]: attributeDefinitionReducer,
  [ATTRIBUTE_TYPE]: attributeReducer,
  [DATA_FIELD_TYPE]: dataFieldReducer,
  [TECHNICAL_DATA_MAPPING_TYPE]: technicalDataMappingReducer,
  [CUSTOM_PROPERTY_GROUP_TYPE]: customPropertyGroupReducer,
  [CUSTOM_PROPERTY_TYPE]: customPropertyReducer,
  [DATA_SOURCE_TYPE]: dataSourceReducer,
  [VALUE_TYPE_TYPE]: valueTypeReducer,
  [USER_TYPE]: userReducer,
  [REQUIREMENT_TYPE]: requirementReducer,
  [BUSINESS_MODEL_MAPPING_TYPE]: businessModelMappingSlice,
  [BUSINESS_MODEL_DIAGRAM_TYPE]: businessModelDiagramSlice,
  [PROCESS_TYPE]: processSlice,
  [PROCESS_STEP_TYPE]: processStepSlice,
  [PROCESS_MAPPING_TYPE]: processMappingSlice,
  [PROCESS_DIAGRAM_TYPE]: processDiagramSlice,
  [bulkOperationsSlice.name]: bulkOperationsSlice.reducer,
  userSettings: userSettingsReducer,
  userMessages: userMessagesReducer,
  notificationCenter: notificationCenterReducer,
  appSettings: appSettingsReducer,
  columnProfiling: columnProfilingReducer,
  dataSetScan: dataSetScanReducer,
});

export default rootReducer;
