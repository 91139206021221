import { useSelector } from 'react-redux';
import { DataSetScanResultState } from './dataSetScanSlice';

type ReduxState = {
  dataSetScan: DataSetScanResultState;
}

export const useSelectLastDataSetScanResult = () => {
  return useSelector((state: ReduxState) => state.dataSetScan);
};
