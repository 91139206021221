import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DataSetScanResultState {
  scanId?: string;
  error: boolean;
}

const initialState: DataSetScanResultState = {
  scanId: undefined,
  error: false,
};

const setDataSetScanResult = (state: DataSetScanResultState, action: PayloadAction<DataSetScanResultState>) => {
  state.scanId = action.payload.scanId;
  state.error = action.payload.error;
};

const dataSetScanSlice = createSlice({
  name: 'dataSetScan',
  initialState: initialState,
  reducers: {
    setDataSetScanResultInStore: setDataSetScanResult,
  },
});

export const {
  setDataSetScanResultInStore,
} = dataSetScanSlice.actions;

export default dataSetScanSlice.reducer;