import { useEffect, useState } from 'react';
import { getDataSetChanges, getDataSetScanResult } from '../api/dataSetSyncApi';
import { DataSetChanges } from '../types';
import { useSelectLastDataSetScanResult } from '../redux/selectors';
import { AccurityFetchError } from '../../rest/types';

// Currently it is necessary for this logic to support two different backend behaviors
// one backend will return a DataSetChanges response for the request
// the other backend will return a UUID response for the request, and later will
// send the DataSetChanges object via websocket which we will get from the redux store
// The second way with UUID and DataSetChanges via websocket will be the only way in the next
// release, so at this point we can revert the commit that added this comment.

export const useDataSetChanges = (dataSetId: string) => {
  const [scanId, setScanId] = useState<string | undefined>();
  const [changes, setChanges] = useState<DataSetChanges>();
  const [error, setError] = useState<boolean>();
  const [fetchError, setFetchError] = useState<AccurityFetchError>();
  const [isLoading, setIsLoading] = useState(false);
  let abortController: AbortController;

  const dataSetScanResult = useSelectLastDataSetScanResult();
  useEffect(() => {
    if (dataSetScanResult.scanId === scanId) {
      if (dataSetScanResult.error || !dataSetScanResult.scanId) {
        setError(true);
        setIsLoading(false);
      } else {
        getDataSetScanResult(dataSetScanResult.scanId)
          .then(response => setChanges(response))
          .catch(() => setError(true))
          .finally(() => setIsLoading(false));
      }
    }
  }, [dataSetScanResult])

  useEffect(() => {
    setIsLoading(true);
    setChanges(undefined);
    setError(undefined);
    setFetchError(undefined);
    abortController = new AbortController();
    getDataSetChanges(dataSetId, abortController.signal)
      .then(response => {
        if (typeof response === 'string') {
          setScanId(response);
        } else {
          setChanges(response);
          setIsLoading(false);
        }
      })
      .catch(fetchError => {
        if (fetchError instanceof DOMException && fetchError.code === DOMException.ABORT_ERR) {
          // the request was aborted, we do not want to handle this error
          return;
        }

        setError(true)
        setFetchError(fetchError);
        setIsLoading(false);
      });

    return () => {
      // abort previous request when data set is changed
      abortController.abort();
    }
  }, [dataSetId]);

  const abortLoading = () => abortController && isLoading
    ? abortController.abort()
    : {};

  return [
    changes,
    isLoading,
    error,
    fetchError,
    abortLoading
  ] as const;
};