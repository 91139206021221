import { getDataSetSyncScanEndpoint, getDataSetSyncScanResultEndpoint, getDataSetSyncUpdateEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { DataSetChanges } from '../types';

export const getDataSetChanges = (dataSetId: string, abortSignal?: AbortSignal): Promise<DataSetChanges> | Promise<string> => {
  const url = getDataSetSyncScanEndpoint(dataSetId);
  return doFetch(url, 'GET', undefined, { abortSignal });
};

export const getDataSetScanResult = (scanUuid: string): Promise<DataSetChanges> => {
  const url = getDataSetSyncScanResultEndpoint(scanUuid);
  return doFetch(url, 'GET', undefined);
};

export const updateDataSet = (dataSetId: string, dataStructureIds: (string | number)[]) => {
  const url = getDataSetSyncUpdateEndpoint(dataSetId);
  return doFetch(url, 'POST', JSON.stringify(dataStructureIds));
};
